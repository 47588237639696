.card-history-item {
  background: white;
  border-color: #e0e0e0;
  border-radius: .30rem;
  overflow: hidden;
}
.card-history-item .card-header, .card-history-item .card-footer .btn-link {
  text-decoration: none;
}
.card-history-item .card-header, .card-history-item .card-footer {
  padding: 0.5rem 1rem;
  background: white;
}
.card-history-item .card-body.grid {
  padding: 0;
  display: flex;
  min-height: 200px;
}
@media (max-width: 767px) {
  .card-history-item .card-body.grid {
    flex-direction: column;
  }
  .card-history-item .card-body.grid .item {
  border-right: none;
}
}

.card-history-item .card-body.grid .item:not(:last-child) {
  border-right: 1px solid #e0e0e0;
  padding: 1rem;
}
.card-history-item .card-body.grid .item.action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}


.card-history-item .card-body.grid .item hr {
  margin-left: -1rem;
  margin-right: -1rem;
}

.card-history-item strong {
  color: #404040;
  font-weight: 500;
}
.card-history-item strong.price {
  color: #f27130;
  font-size: 16px;
  font-weight: 500;
}
.card-history-item label {
  color: #9e9e9e;
  /* font-weight: 500; */
  display: block;
  margin-bottom: .1rem;
}

.card-history-item .card-body.grid .type,
.card-history-item .card-body.grid .location,
.card-history-item .card-body.grid .status,
.card-history-item .card-body.grid .action {
  flex-grow: 1;
}

.card-history-item .card-body.grid .type img {
  display: inline-block;
  margin-right: 15px;
  margin-top: 5px;
}
.card-history-item .card-body.grid .type .list {
  display: flex;
  align-items: flex-start;
}

.list-group.location li {
  border: none;
  overflow: hidden;
}
.list-group.location li:last-child {
  padding-top: 0;
}  
.list-group.location li::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #27a397;
  top: 18px;
}

.list-group.location li:last-child::before {
  top: 6px;
}

.list-group.location li:not(:last-child)::after {
  content: '';
  width: 2px;
  background: #e0e0e0;
  height: 100%;
  position: absolute;
  left: 4px;
  top: 45px;
  margin: -10px 0;
}

.list-group.location li:not(:first-child)::before {
  background-color: #27a397;
}

.ellipsis {
    display: block;
    width: 100%;
    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 30ch;
}