.head-home-text {
  font-size: 18px;
  font-weight: 500;
  color: #404040;
}

.card-home {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
}

.card-home .card-img {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-home .card-img img {
  max-width: 60px;
}
.card-home .card-title {
  font-size: 16px;
  font-weight: 900;
  color: #404040;
  margin-bottom: 5px;
}

.card-home .card-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #404040;
}

.footer-track-button {
  background-color: white;
  padding: 1.25rem;
  border-top: 0;
}

.footer-track-button .btn {
    color: #27A397 !important;
    background: white !important;
    border-color: #27A397 !important;
}