.hard-round {
    border-radius: .4rem !important
}

@media (min-width: 451px) {
    .info-body {
        padding-bottom: 70px
    }
}

@media (max-width: 450px) {
    .pos-unset-xs {
        position: unset !important
    }
}

.scrollable {
    /*height: 100vh;*/
    overflow-y: scroll;
    scrollbar-width: none;
}

.scrollable::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
}

.est-price {
    color: #f27130;
    font-weight: 500;
}