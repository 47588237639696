.marker:hover {
    cursor: pointer!important;
}

.marker-label {
    margin-left: 10px;
    background: #fecf33;
    color: #fff;
    border-radius: 90%;
    font-size: 10px;
    height: 20px;
    width: 20px;
    display: block;
    text-align: center;
    padding-top: 3px;
    border: 2px solid white;
}

#gmaps-container {
  height: calc(100vh - 60px);
  width: 100%;
}

@media (max-width: 450px) {
  #gmaps-container {
    height: 100vh;
  }
}
