ol.num {
    counter-reset: item
}

li.num {
    display: block
}

li.num:before {
    content: counters(item, ".") ". ";
    counter-increment: item
}

.no-num::before {
    content: "";
}

.lola {
    list-style-type: lower-latin;
}
