.email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    padding: 0 20px 0;
}

.footer .cc-logo {
  max-height: 1.2em;
  padding: 0.2em;
  margin: 0.2em 0.4em;
}

.footer {
  color: white;
  margin: 1em 2em;
}

.footer.footer-absolute {
  position: absolute;
  bottom: 2em;
  color: white;
  left: 2em;
  margin: 0;
}

.footer .cc-container {
  background-color: white;
  width: 100%;
}
