.stepper-container {
    display: flex;
}

.stepper-container .stepper-item {
    border-radius: 0 !important;
    /* border-left: solid 1px #efefef; */
    border-bottom: solid 1px #efefef;
    padding-top: 10px;
    padding-bottom: 10px;
    flex: 1;
    text-align: center;
    position: relative;
}

.stepper-container .stepper-item::after {
    content: '';
}

.stepper-container:first-child .stepper-item {
    /* border-left: none; */
    border-radius: 0.4rem 0 0 0;
}

.stepper-container:last-child .stepper-item {
    border-radius: 0 0.4rem 0 0;
}

.stepper-container .stepper-item .arrow {
    position: absolute;
    right: -12px;
    top: 24px;
}

.bg-step {
    width: 20px;
    height: 20px;
}

.dash {
    margin:5px 0;
    height:2px;
    width: 30px;
    background: repeating-linear-gradient(to right,white 0,white 10px,transparent 10px,transparent 12px)
    /*10px red then 2px transparent -> repeat this!*/
}