.swal-wide {
    width: 850px !important;
}

.bottom-item {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.top-item {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

/*
.MuiDialog-paperWidthSm {
    width: 800px !important;
    max-width: 800px !important;
}*/
