.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.input-scheduled .icon {
  font-size: 12px;
}

.input-scheduled .MuiInput-underline::before {
  content: none !important;
}
.input-scheduled .Mui-error.MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
}
.input-scheduled .MuiInput-input {
  font-size: 12px;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

}
.alert.alert-danger.alert-jabodetabek {
  background-color: #fcd8d8;
  border-color: #ff1d31;
  color: #ff1d31;
  font-size: 10px;
  padding: 10px;
  border-radius: 5px;
}
