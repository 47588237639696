.bg-empty {
    /* The image used */
    background-image: url("/assets/img/empty.png");

    /* Full height */
    height: 400px;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.tab-history {
    border: none;
    background: transparent;
}
.tab-history .card-body {
    background: white;
    border: 1px solid #e1e8ee;
    border-radius: 2px;
}
.tab-history .card-header {
    background-color: transparent;
    border-bottom: none;
}
.tab-history .nav-item {
    margin-right: 5px;
}
.tab-history .nav-link {
    color: #a5a5a5;
}
.tab-history .nav-link.active {
    font-weight: 500;
    background-color: white;
    color: #27A397 !important;
    border-bottom: none;
}
.tab-history .btn-single-item {
    border-radius: 20px;
    background-color: white;
    border: 1px solid #d9d9d9;
}