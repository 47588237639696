.list-group.location .list-destination::before {
  background-color: #27a397;
}

.order-form .list-location-form:not(:last-child) .list-group.location .list-group-item {
  overflow: visible;
}

.order-form .list-location-form:not(:last-child) .list-group.location .list-group-item::after {
  content: '';
  width: 1px;
  border-right: 1px dashed #e0e0e0;
  height: 250%;
  position: absolute;
  left: 4px;
  top: 30px;
  margin: -10px 0;
}

.swal2-container {
  z-index: 9999 !important;
}